import { ClientBroadcasterDayRestrictionForm } from "../components/admin/igsp/adminTypes";
import { BadgeVariant } from "../components/shared/BatterBadge";
import { DayOfWeek } from "../types/core";
import { IgspAdminSlug, IgspDecoderModel, IgspNetwork, IgspRequest, IgspRequestStatus } from "../types/igsp";
import { formatDate, formatTime } from "./date";
import { compareLists } from "./list";
import { isBlank, testFields } from "./string";

export const getDecoderName = (decoder: IgspDecoderModel) => {
  return `${decoder.brand.name} ${decoder.name}`;
};

export const filterAdminTable = <T extends object>(
  slug: IgspAdminSlug,
  data: T[],
  filterText: string,
  active?: boolean | null
): T[] => {
  if (isBlank(filterText)) {
    return active != null
      ? data.filter(s => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const activeValue = slug === "network" ? (s as IgspNetwork).source?.isActive : ((s as any).active as boolean);
          return activeValue === active;
        })
      : data;
  }

  // with the filter text, search through ALL the different text fields in an object
  const text = filterText.trim();
  return data.filter(s => {
    if (slug === "network") {
      const network = s as IgspNetwork;
      return testFields([network.source?.description], text);
    }

    return testFields(
      Object.values(s).filter(v => typeof v === "string"),
      text
    );
  });
};

export const compareDayRestrictions = (
  a: Record<DayOfWeek, ClientBroadcasterDayRestrictionForm>,
  b: Record<DayOfWeek, ClientBroadcasterDayRestrictionForm>
) => {
  // first check if the keys are the same
  if (!compareLists(Object.keys(a).sort(), Object.keys(b).sort())) {
    return false;
  }

  return compareLists(
    Object.values(a).sort((a, b) => a.day.localeCompare(b.day)),
    Object.values(b).sort((a, b) => a.day.localeCompare(b.day)),
    (a, b) =>
      a.fullyRestricted === b.fullyRestricted &&
      a.totalGames === b.totalGames &&
      a.beforeTime === b.beforeTime &&
      a.afterTime === b.afterTime
  );
};

export const getStatusVariant = (status: IgspRequestStatus): BadgeVariant => {
  switch (status) {
    case "ENGINEERING":
      return "warning";
    case "FINALIZED":
      return "success";
    case "DENIED":
      return "danger";
    default:
      return "default";
  }
};

export const parseRequest = (request?: IgspRequest | null) => {
  if (!request) {
    return "";
  }

  const { gameDate, gameTime, homeTeam, awayTeam } = request.game;
  const date = formatDate(gameDate, "M/d");
  const time = formatTime(gameTime, "h:mma");

  const prefix = !date && !time ? "TBD" : `${date || "TBD"} ${time || "TBD"}`;
  const suffix = `${awayTeam.abbrev} @ ${homeTeam.abbrev}`;

  return `${prefix} ${suffix}`;
};
